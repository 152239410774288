.error-page{
    .content{
        display: flex;
        flex-direction: column;
    }
}
.error-content-section {
    margin: auto;
    padding-top: 80px;
    padding-bottom: 80px;
    @media(max-width:1450px){
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @media(max-width:1024px){
        padding-top: 25px;
    }
    .error__title{
        margin-bottom: 11px;
        font-weight: 700;
        text-align: center;
        @media(max-width:1024px){
            margin-bottom: 10px;
        }
    }
    .error__text{
        text-align: center;
        margin-bottom: 30px;
        @media(max-width:1024px){
            margin-bottom: 20px;
        }
    }
}
.error-content{
    &-box{
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        

    }
    &__photo {
        width: 100%;
        max-width: 575px;
        position: relative;
        z-index: 0;
        img{
            width: 100%;
            object-fit: contain;
            pointer-events: none;
        }
    }

    &__info {
        margin-top: -28px;
        position: relative;
        z-index: 1;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        @media(max-width:1024px){
            margin-top: -17px;
        }
    }
    
}