.faq-section {
    background-color: $baseBack;
    padding-top: 370px;
    padding-bottom: 220px;
    @media(max-width:1300px){
        padding-top: 280px;
    }
    @media(max-width:1024px){
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .faq__title{
        margin-bottom: 15px;
        @media(max-width:1024px){
            margin-bottom: 10px;
        }
    }
    .faq__text {
        margin-bottom: 45px;
        @media(max-width:1024px){
            margin-bottom: 20px;
        }
    }
}