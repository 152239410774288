.homepage-hero-section {
    padding-top: 40px;
    padding-bottom: 40px;
    @media(max-width:1024px){
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

// hero

.hero{
    &-box{
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media(max-width:800px){
            flex-direction: column;
        }
    }


    &__info{
        width: 100%;
        max-width: 720px;
        min-width: 715px;
        position: relative;
        z-index: 1;
        .buble__img{
            width: 195px;
            height: 195px;
            z-index: -1;
            top: 0;
            left: 0;
            position: absolute;
            transform: translateX(-50%) translateY(-45% );
            animation: gradient 2s infinite ease;
            animation-delay: 1s;
            @media(max-width:1024px){
                width: 90px;
                height: 90px;
                transform: translateX(-40%) translateY(-20% );
            }
        }
        @media(max-width:1350px){
            min-width: 600px;
        }
        @media(max-width:1200px){
            min-width: 500px;
        }
        @media(max-width:1024px){
            min-width: 420px;
            max-width: 420px;
        }
        @media(max-width:900px){
            min-width: 350px;
            max-width: 350px;
        }
        @media(max-width:800px){
            max-width: 100%;
            min-width: 100%;
            margin-bottom: 35px;
        }
    }
    &__photo{
        width: 100%;
        max-width: 580px;
        position: relative;
        z-index: 1;
        margin-left: 70px;
        @media(max-width:1200px){
            margin-left: 40px;
        }
        @media(max-width:1024px){
            max-width: 460px;
        }
        @media(max-width:800px){
            max-width: 400px;
            margin-left: 0;
        }
        img{
            width: 100%;
            position: relative;
            object-fit: contain;
            pointer-events: none;
        }
        .buble__img{
            width: 150px;
            height: 150px;
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translateX(60%) translateY(17%);
            z-index: -1;
            animation: gradient 2s infinite ease;
            @media(max-width:1024px){
                width: 90px;
                height: 90px;
                transform: translateX(40%) translateY(10%);
            }
        }
    }
    &__text{
        max-width: 480px;
        margin-bottom: 45px;
        @media(max-width:1350px){
            margin-bottom: 35px;
        }
        @media(max-width:1024px){
            margin-bottom: 20px;
        }
    }

    &__title{
        @media(max-width:800px){
            max-width: 520px;
        }
        
    }
}