.achievement-section {
    padding-top: 50px;
    padding-bottom: 35px;
    @media(max-width:1024px){
        padding-top: 30px;
        padding-bottom: 25px;
    }
    @media(max-width:576px){
        padding-top: 45px;
    }
    .global-container {
        overflow: hidden;
        @media(max-width:1024px){
            overflow: visible;
        }
    }
    .achievement__title{
        margin-bottom: 15px;
        @media(max-width:1024px){
            margin-bottom: 10px;
        }
    }
    .achievement__text {
        margin-bottom: 70px;
        z-index: 1;
        position: relative;
        @media(max-width:576px){
            margin-bottom: 40px;
        }
    }
    .swiper-slide{
        width: 100%;
        height: auto;
    }
}

.achievement{
    &-slider{
        width: 100%;
        position: relative;
        visibility: visible;
        overflow: visible;
        @media(max-width:1024px){
            width: calc(100% + 30px);
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    &-slide{
        width: 100%;
        position: relative;
        height: 100%;
        background-color: $baseBack;
        border-radius: 10px;
        padding: 30px 44px;
        padding-bottom: 45px;
        @media(max-width:1300px){
            padding: 25px 35px;
            padding-bottom: 30px;
        }
        @media(max-width:1024px){
            padding: 20px 15px;
            border-radius: 0;
        }
    }
    &-slide__info{
        position: relative;
        z-index: 1;
        max-width: 440px;
        @media(max-width:576px){
            max-width: 320px;
        }
    }
    &-slide__name {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: $baseBlack;
        margin-bottom: 5px;
        @media(max-width:1350px){
            font-size: 18px;
            line-height: 22px;
        }
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
        }
    }
    &-slide__type{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: $baseBlack;
        margin-bottom: 15px;
        @media(max-width:1300px){
            font-size: 14px;
            margin-bottom: 10px;
        }
        @media(max-width:1024px){
            line-height: 16px;
        }
    }
    &-slide__text {
        position: relative;
    }
    &-slide__text-item {
        padding: 10px 20px;
        width: fit-content;
        background-color: $baseBlue;
        border-radius: 5px;
        margin-bottom: 10px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: $baseWhite;
        @media(max-width:1300px){
            padding: 5px 12px;
            font-size: 14px;
            line-height: 130%;
            border-radius: 4px;
        }
        @media(max-width:1024px){
            padding: 5px 10px;
            line-height: 16px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    &-slide__photo{
        width: 100%;
        max-width: 482px;
        position: absolute;
        bottom: 0;
        pointer-events: none;
        z-index: 0;
        right: -90px;
        @media(max-width:1450px){
            right: -120px;
        }
        @media(max-width:1300px){
            max-width: 400px;
            right: -100px;
        }
        @media(max-width:1100px){
            max-width: 350px;
            right: -90px;
        }
        @media(max-width:1024px){
            max-width: 330px;
        }
        @media(max-width:800px){
            max-width: 360px;
        }
        @media(max-width:576px){
            max-width: 330px;
            right: -85px;

        }
        @media(max-width:450px){
            max-width: 300px;
            right: -85px;
            bottom: 3px;
        }
        
        img{
            width: 100%;
            object-fit: contain;
        }
        &.md-photo{
            bottom: -10px;
            max-width: 526px;
            right: -165px;
            @media(max-width:1450px){
                max-width: 480px;
                right: -120px;
            }
            @media(max-width:1300px){
                max-width: 400px;
                right: -100px;
                bottom: -20px;
            }
            @media(max-width:1100px){
                max-width: 350px;
                right: -80px;
                bottom: 0;
            }
            @media(max-width:1024px){
                max-width: 320px;
                right: -70px;
                bottom: -5px;

            }
            @media(max-width:800px){
                max-width: 360px;
                right: -80px;
                bottom: -10px;
            }
            @media(max-width:576px){
                max-width: 320px;
                right: -70px;
                bottom: 0;
            }
            @media(max-width:450px){
                max-width: 280px;
                right: -65px;
            }
        }
        &.xl-photo{
            max-width: 580px;
            right: -95px;
            bottom: -20px;
            @media(max-width:1450px){
                max-width: 500px;
                right: -80px;
                bottom: 0;
            }
            @media(max-width:1300px){
                max-width: 450px;
                right: -65px;
                bottom: -15px;

            }
            @media(max-width:1200px){
                max-width: 380px;
                right: -50px;
                bottom: -5px;
            }
            @media(max-width:1100px){
                max-width: 300px;
                right: -40px;
                bottom: 20px;
            }
            @media(max-width:1024px){
                max-width: 280px;
                right: -35px;
            }
            @media(max-width:800px){
                max-width: 380px;
                bottom: 0;
            }
            @media(max-width:576px){
                max-width: 320px;
                bottom: 10px;
                right: -40px;
            }
            @media(max-width:450px){
                max-width: 260px;
                bottom: 20px;
                right: -35px;
            }
        }
    }
}