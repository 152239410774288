.global-btn{
  cursor: pointer;
  outline: none;
  background: $baseWhite;
  min-height: 60px;
  min-width: 325px;
  border: 1px solid $baseBlue;
  color: $baseBlue;
  border-radius: 5px;
  padding: 10px 20px;
  transition: .3s;
  position: relative;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  z-index: 1;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &::after{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%) scale(0);
    transition: .17s;
    z-index: -1;
    background-color: $baseBlue;
    content: '';
  }
  .icon{
    fill: $baseBlue;
    transition: .3s;
    margin-left: 14px;
    max-width: 36px;
    max-height: 36px;
    object-fit: contain;
  }
  .icon-long-arrow{
    width: 36px;
    height: 8px;
  }
  &:hover{
    color: $baseWhite;
    .icon{
      fill: $baseWhite;
    }
  }
  &:hover::after{
    transform: translate(50%, -50%) scale(1.1);
  }
  
  &.gradient-btn{
    background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
    color: $baseWhite;
    border: none;
  }
  &.gradient-btn::after{
    background: linear-gradient(270deg, #88BCF9 0%, #B458EC 100%);
    transform: translate(50%, -50%) scale(1);
    opacity: 0;
    transition: .5s;
   
  }
  &.gradient-btn:hover::after{
    opacity: 1;
    // animation: gradient 2s infinite ease;
  }
  &.white-btn{
    &::after{
      background-color: $basePurple;
    }
  }

  @media(max-width:1024px){
    min-height: 50px;
    font-size: 14px;
    line-height: 18px;
    padding: 7px 15px;

  }
  @media(max-width:450px){
    min-width: 100%;
  }
}


// slider buttons
.slider-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  @media(max-width:1024px){
    margin-top: 20px;
  }
}

.slider-button{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  transition: .3s;
  background-color: $baseBlue;
  @media(max-width:1024px){
    width: 35px;
    height: 35px;
    margin-right: 12px;
    border-radius: 4px;
  }
  &:last-child{
    margin-right: 0;
  }
  .icon{
    width: 7px;
    height: 12px;
    fill: $baseWhite;
    transition: .3s;
    object-fit: contain;
    @media(max-width:1024px){
      width: 6px;
      height: 10px;
    }
  }

  &.slider-prev{
    .icon{
      transform: scale(-1,1);
    }
  }
  &:hover{
    @media(min-width: 1025px){
      background-color: $secondaryBlue;
    }
  }
  &.swiper-button-disabled{
    opacity: 0.6;
    pointer-events: none;
  }
}

// slider buttons end

// detail btn

.more-detail-btn {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  cursor: pointer;
  transition: .3s;
  &:hover{
    @media(min-width: 1025px){
      color: $baseBlue;
    }
  }
  color: $baseBlack;
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: from-font;
  @media(max-width:1024px){
    font-size: 14px;
    line-height: 20px;
  }
}

// detail btn end