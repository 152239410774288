$ViewPort: false;
// LIBS
@import "libs";

// Custom mixins
@import 'vars/index';

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

.lock{
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
  touch-action: none;
}
.disable-display{
  display: none !important;
}

body {
  opacity: 0;
  transition: .5s;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  line-height: 1.3;
  font-size: 16px;
  scrollbar-gutter: stable;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: $baseBlack;
  background-color: $baseWhite;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

ul, ol, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button,
textarea {
  outline: none !important;
  border-radius: 0 ;
  appearance: none;
}

button{
  cursor: pointer;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  padding-top: 152px;
  @media(max-width:1024px){
    padding-top: 95px;
  }
}

.global-wrapp{
  width: 100%;
  position: relative;
  padding: 40px 30px;
  background-color: $baseWhite;
  @media(max-width:1024px){
    padding: 20px 15px;
  }
}

.global-container{
  width: 100%;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}

.global-text{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: $baseBlack;
  margin-bottom: 40px;
  @media(max-width:1350px){
    margin-bottom: 30px;
  }
  @media(max-width:1024px){
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.global-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  color: $baseBlack;
  margin-bottom: 35px;
  @media(max-width:1350px){
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 30px;
  }
  @media(max-width:1024px){
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
  }
}
.global-subtitle{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: $baseBlack;
  margin-bottom: 7px;
  @media(max-width:1350px){
    font-size: 28px;
    line-height: 36px;
  }
  @media(max-width:1024px){
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10px;
  }
}

button,textarea, input{
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
}

.content {
  flex: 1 0 auto;
}


// Modules & Components styles
@import "modules";
@import "components";
