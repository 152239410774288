.social{
  &__list{
    display: inline-flex;
    align-items: center;
    li{
      margin: 0 12px;
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
  &__item{
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
    @media(max-width:1350px){
      width: 18px;
      height: 18px;
    }
    .icon{
      max-width: 21px;
      max-height: 21px;
      fill: $baseBlack;
      transition: .3s;
      @media(max-width:1350px){
        max-width: 18px;
        max-height: 18px;
      }
    }

    &:hover{
      .icon{
        fill: $baseBlue;
      }
    }
  }
}