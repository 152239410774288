.banner-quize-section {
    padding-top: 0;
    padding-bottom: 0;
    pointer-events: none;
    background-color: transparent;
    z-index: 2;
    @media(max-width:1024px){
        pointer-events: all;
        padding-top: 30px;
        padding-bottom: 40px;
        background-color: $baseWhite;
    }
}