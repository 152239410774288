.accordeon{
    &__list{
        width: 100%;
        position: relative;
        z-index: 1;
    }
    &__item{
        position: relative;
        margin-bottom: 15px;
        transition: .3s;
        overflow: hidden;
        border: 1px solid transparent;
        border-radius: 5px;
        @media(max-width:1024px){
            margin-bottom: 10px;
        }
        li{
            font-weight: 500;
            position: relative;
            font-size: 14px;
            line-height: 18px;
            padding-left: 18px;
            margin-bottom: 9px;
            &:last-child{margin-bottom: 0;}
          }
        li::before{
            background: url('../svg/marker.svg') no-repeat;
            background-size: 7px 12px;
            width: 7px;
            height: 12px;
            display: flex;
            content: '';
            position: absolute;
            left: 0;
            top: 3.5px;
            @media(max-width:1024px){
                top: 2px;
            }
        }
        &:last-child{
            margin-bottom: 0;
        }
        &.active{
            background-color: $baseWhite;
            .accordeon__btn .icon-minus{
                opacity: 1;
            }
            .accordeon__btn .icon-plus{
                opacity: 0;
            }
            .accordeon__trigger{
                background-color: $baseBlue;
                border-left-color:$baseBlue ;
                border-top-color:$baseBlue ;
                border-right-color:$baseBlue;
            }
            .accordeon__name{
                color: $baseWhite;
            }
            .accordeon__btn .icon{
                fill: $baseWhite;
            }
            border-color: $baseGray;
        }
    }
    &__trigger{
        min-height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $baseGray;
        border-radius: 4px;
        transition: .3s;
        padding: 15px 30px;
        position: relative;
        padding-right: 55px;
        cursor: pointer;
        @media(max-width:1024px){
            min-height: 50px;
            padding: 10px 15px;
            padding-right: 40px;
        }
    }
    &__name{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        transition: .3s;
        line-height: 24px;
        color: $baseBlack;
        @media(max-width:1350px){
            font-size: 18px;
            line-height: 22px;
        }
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
        }
    }

    &__btn{
        display: flex;
        align-items: center;
        width: 21px;
        height: 21px;
        transition: .3s;
        position: absolute;
        right: 20px;
        top: 50%;
        cursor: pointer;
        transform: translateY(-50%);
        justify-content: center;
        z-index: 1;
        @media(max-width:1024px){
            width: 15px;
            height: 15px;
            right: 15px;
        }
        .icon{
            width: 21px;
            height: 21px;
            object-fit: contain;
            fill: $baseBlack;
            transition: .3s;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            @media(max-width:1024px){
                width: 15px;
                height: 15px;
            }
        }
        .icon-minus{
            opacity: 0;
        }
        
    }

    &__content{
        display: none;
        padding: 30px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        color: $baseBlack;
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 20px;
            padding: 10px 15px;
        }
        span{
            color: #CB8EF5;
            font-weight: 700;
        }
    }
}