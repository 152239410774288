.form{
    &__field{
        width: 100%;
        position: relative;
        margin-bottom: 20px;
    }
    &__field-row {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
    &__field-name{
        max-width: 50%;
        padding-right: 5px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: $thirdGray;
        margin-bottom: 10px;
        @media(max-width:1024px){
            font-size: 12px;
            margin-bottom: 7px;
        }
    }
    &__field-require{
        max-width: 50%;
        padding-left: 5px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        text-align: right;
        text-decoration-line: underline;
        margin-bottom: 10px;
        text-align: right;
        color: $baseRed;
        transition: .3s;
        opacity: 0;
        visibility: hidden;
        @media(max-width:1024px){
            font-size: 12px;
            margin-bottom: 7px;
        }
    }
    &__field-input{
        width: 100%;
        position: relative;

    }
    &__input{
        width: 100%;
        position: relative;
        min-height: 60px;
        border-radius: 5px;
        border: 1px solid #E0E0E0;
        transition: .3s;
        display: flex;
        align-items: center;
        padding: 15px 28px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: $baseBlack;
        @media(max-width:1024px){
            font-size: 14px;
            padding: 10px 15px;
            min-height: 50px;
        }
        &::placeholder{
            color: $thirdGray;
            transition: .3s;
        }
        &:hover{
            border-color: $baseBlue;
        }
        &:focus{
            border-color: $basePurple;
        }
        &:hover::placeholder{
            color: $baseBlue;
        }
        &:focus::placeholder{
            color: $basePurple;
        }
    }

    &__checkbox{
        width: 100%;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;
        input{
            display: none;
        }
        input:checked+ .form__checkbox-retangle{
            border-color: $baseBlue;
            .icon{
                opacity: 1;
            }
        }
        &:hover{
            .form__checkbox-retangle{
                border-color: $baseBlue;
            }
            .form__checkbox-text{
                color: $baseBlue;
            }
        }
    } 
    &__checkbox-retangle{
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
        margin-right: 12px;
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        .icon{
            width: 11px;
            height: 9px;
            opacity: 0;
            object-fit: contain;
            // transform: translateX(1px) translateY(0.5px);
            fill: $baseBlue;
            transition: .3s;
        }
    }
    &__checkbox-text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        transition: .3s;
        line-height: 100%;
        color: $secondaryBlack;
    }

    // radio

    &__radio{
        width: auto;
        display: inline-flex;
        cursor: pointer;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;
        input{
            display: none;
        }
        input:checked+ .form__radio-retangle{
            border-color: $basePurple;
            background-color: $basePurple;
            .icon{
                opacity: 1;
            }
        }
        &:hover{
            .form__radio-retangle{
                background-color: $basePurple;
            }
        }
    }
    &__radio-retangle{
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
        margin-right: 12px;
        border: 1px solid $baseWhite;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        .icon{
            width: 11px;
            height: 9px;
            opacity: 0;
            object-fit: contain;
            // transform: translateX(1px) translateY(0.5px);
            fill: $baseWhite;
            transition: .3s;
        }
    }
    &__radio-text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        transition: .3s;
        color: $baseWhite;
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 16px;
        }
    }
}

.form-item{
    &.error, &.error__email{
        .form__input{
            border-color: $baseRed !important;
            &::placeholder{
                color: $baseRed !important;
            }
        }
        .form__field-require{
            opacity: 1;
            visibility: visible;
        }
        .form__checkbox-retangle{
            border-color: $baseRed !important;
        }
        .form__checkbox-text{
            color: $baseRed !important;
        }
    }
}