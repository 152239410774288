.offer-section {
    padding-bottom: 10px;
    .offer__text{
        max-width: 870px;
        margin-bottom: 70px;
        @media(max-width:1350px){
            margin-bottom: 60px;
        }
        @media(max-width:1024px){
            margin-bottom: 40px;
        }
    }
    @media(max-width:1024px){
        padding-bottom: 10px;
        padding-top: 30px;
    }
    @media(max-width:576px){
        padding-bottom: 10px;
    }
    
}

.offer{
    &__list{
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        @media(max-width:1024px){
            width: calc(100% + 20px);
            margin-left: -10px;
            margin-right: -10px;
        }
    }
    &__item{
        width: 33.3333333333333333%;
        position: relative;
        padding: 0 20px;
        margin-bottom: 48px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:nth-child(3n-2){
           align-items: flex-start;
            @media(max-width:1200px){
                .offer-card-popup{
                    right: -15px !important;
                    left: unset;
                    transform: translateX(100%);
                }
           }
           @media(max-width:1024px){
            .offer-card-popup{
                right: unset !important;
            }
           }
        }
        &:nth-child(3n){
            align-items: flex-end;
            .offer-card-popup{
                right: auto;
                left: -20px;
                transform: translateX(-100%);
            }
            @media(max-width:1200px){
                .offer-card-popup{
                    left: -15px !important;
                    transform: translateX(-100%);
                }
           }
           @media(max-width:1024px){
            .offer-card-popup{
                left: unset !important;
            }
           }
        }
        @media(max-width:1024px){
            width: 50%;
            align-items: center !important;
            padding: 0 10px;
            margin-bottom: 40px;
        }
        @media(max-width:576px){
            width: 100%;
            &:last-child{margin-bottom: 0;}
        }

    }
    &__card{
        width: fit-content;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 300px;
        @media(max-width:576px){
            min-width: 100%;
        }
        &.active{
            .offer-card__btn{
                color: $baseBlue;
                @media(max-width:1024px){
                    margin-bottom: 15px;
                }
            }
            .offer-card-popup{
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &-card__photo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 210px;
        height: 210px;
        margin-bottom: 20px;
        img{
            max-width: 100%;
            max-height: 210px;
            object-fit: contain;
            pointer-events: none;
        }
    }
    &-card__info{
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &-card__btn{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        position: relative;
        text-align: center;
        cursor: pointer;
        transition: .3s;
        cursor: help;
        color: $baseBlack;
        text-decoration-line: underline;
        text-decoration-style: dashed;
        text-decoration-thickness: from-font;
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 18px;
        }
        &:hover{
            @media(min-width: 1025px){
                color: $baseBlue;
            }
            
        }
    }
    // popup 
    &-card__popup-wrap{
        display: inline-flex;
        flex-direction: column;
        position: relative;
    }

    &-card-popup{
        width: 540px;
        padding: 35px;
        background: $baseWhite;
        box-shadow: 16px 22px 60px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        position: absolute;
        z-index: 1;
        right: -20px;
        bottom: 0;
        transform: translateX(100%);
        transition: .4s;
        opacity: 0;
        visibility: hidden;
        @media(max-width:1450px){
            width: 500px;
        }
        @media(max-width:1400px){
            width: 450px;
            padding: 30px;
        }
        @media(max-width:1300px){
            width: 420px;
        }
        @media(max-width:1200px){
            width: 400px;
            padding: 25px 20px;
            // bottom: 50% !important;
            left: 50% ;
            transform: translateX(-50%);
        }
        @media(max-width:1024px){
            position: relative;
            right: unset !important;
            left: unset !important;
            bottom: unset !important;
            transform: none !important;
            opacity: 1;
            visibility: visible;
            width: 100%;
            display: none;
            padding: 20px 15px;
        }
    }
    &-card-popup__close{
        position: absolute;
        top: 20px ;
        right: 20px;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @media(max-width:1400px){
            top: 15px;
            right: 15px;
        }
        @media(max-width:1200px){
            top: 12px;
            right: 12px;
            width: 12px;
            height: 12px;
        }
        @media(max-width:1024px){
            right: 10px;
            width: 10px;
            height: 10px;
            top: 10px;
        }
        .icon{
            fill: $secondaryGray;
            transition: .3s;
            max-width: 14px;
            max-height: 14px;
            object-fit: contain;
            @media(max-width: 1200px){
                max-width: 12px;
                max-height: 12px;
            }
            @media(max-width:1024px){
                max-width: 10px;
                max-height: 10px;
            }
        }
        &:hover .icon{
            fill:  $basePurple;
        }
    }
    &-card-popup__title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        color: $baseBlue;
        margin-bottom: 12px;
        @media(max-width:1350px){
            font-size: 18px;
            margin-bottom: 10px;
        }
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
        }
    }

    &-card-popup__info {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 150%;
        color: $baseBlack;
        @media(max-width:1024px){
            font-size: 12px;
            line-height: 16px;
        }
        li{
            font-weight: 500;
            position: relative;
            padding-left: 18px;
            margin-bottom: 9px;
            &:last-child{margin-bottom: 0;}
          }
          li::before{
            background: url('../svg/marker.svg') no-repeat;
            background-size: 7px 12px;
            width: 7px;
            height: 12px;
            display: flex;
            content: '';
            position: absolute;
            left: 0;
            top: 3.5px;
            @media(max-width:1024px){
                top: 2px;
            }
          }
          
    }
}