.review-section {
    padding-top: 70px;
    padding-bottom: 50px;
    @media(max-width:1024px){
        padding-top: 40px;
        padding-bottom: 30px;
    }
    .review__title{
        margin-bottom: 23px;
        @media(max-width:1024px){
            margin-bottom: 10px;
        }
    }
    .review__text {
        margin-bottom: 50px;
        @media(max-width:1350px){
            margin-bottom: 40px;
        }
        @media(max-width: 1024px){
            margin-bottom: 20px;
        }
    }
    .slider-buttons{
        margin-top: 48px;
        @media(max-width:1024px){
            margin-top: 20px;
        }
    }
}

.review{
    &-slider{
        width: 100%;
        position: relative;
        z-index: 1;
        overflow: hidden;
        .swiper-slide{
            height: auto;
        }
    }
    &-wrap{
        width: 100%;
        position: relative;
        z-index: 1;
        .buble__img{
            width: 100px;
            height: 100px;
            position: absolute;
            bottom: 40%;
            left: 0;
            transform: translateX(-75%) translateY(0%);
            z-index: -1;
            animation: gradient 2s infinite ease;
            @media(max-width:1024px){
                width: 60px;
                height: 60px;
                transform: translateX(-70%) translateY(0%);
            }
        }
        .buble-two__img{
            width: 170px;
            height: 170px;
            z-index: -1;
            top: 0;
            right: 0;
            position: absolute;
            transform: translateX(60%) translateY(-60% );
            animation: gradient 2s infinite ease;
            animation-delay: 1s;
            @media(max-width:1024px){
                width: 80px;
                height: 80px;
                transform: translateX(60%) translateY(-40% );
            }
        }

    }
    &-card{
        width: 100%;
        position: relative;
        height: 100%;
        background-color: $baseWhite;
        border: 1px solid #CACACA;
        border-radius: 5px;
        padding: 45px 25px;
        padding-left: 45px ;
        padding-bottom: 30px;
        @media(max-width:1350px){
            padding: 35px 25px;
            padding-right: 35px;
            padding-bottom: 25px;
        }
        @media(max-width:1024px){
            padding: 20px 15px;
        }
    }
    &-card__header{
        width: 100%;
        display: flex;
        align-items: center;
        min-height: 55px;
        margin-bottom: 30px;
        @media(max-width:1450px){
            min-height: 50px;
        }
        @media(max-width:1024px){
            margin-bottom: 20px;
            min-height: 40px;
        }
    }
    &-card__logo {
        min-width: fit-content;
        display: flex;
        max-height: 55px;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        @media(max-width:1450px){
            max-height: 50px;
        }
        @media(max-width:1300px){
            max-height: 40px;
        }
        &.small-img img{
            @media(max-width:1300px){
                max-height: 15px;
            }
            @media(max-width:1024px){
                max-height: 13px;
            }
        }
        img{
            width: auto;
            pointer-events: none;
            object-fit: contain;
            max-height: 55px;
            @media(max-width:1450px){
                max-height: 50px;
            }
            @media(max-width:1300px){
                max-height: 40px;
            }
        }
    }
    &-card-header__info{
        width: 100%;
    }

    &-card__name{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: $baseBlack;
        @media(max-width:1350px){
            font-size: 14px;
            line-height: 20px;
        }
    }
    &-card__company {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: $baseBlack;
        @media(max-width:1350px){
            font-size: 14px;
            line-height: 20px;
        }
    }

    &-card__content{
        width: 100%;
        position: relative;
    }
    &-card__text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 150%;
        color: $baseBlack;
        .review-card__text-hide{
            display: none;
        }
    }
    &-card__btn{
        margin-top: 15px;
    }
}