.additionally-section {
    padding-top: 50px;
    padding-bottom: 45px;
    @media(max-width:1024px){
        padding-top: 25px;
        padding-bottom: 40px;
    }
}

.additionally{
    &-wrap{
        width: 100%;
        position: relative;
        z-index: 1;
        .buble__img{
            width: 140px;
            height: 140px;
            position: absolute;
            bottom: 50%;
            right: 0;
            transform: translateX(-5%) translateY(60%);
            z-index: -1;
            animation: gradient 2s infinite ease;
            @media(max-width:1024px){
                width: 90px;
                height: 90px;
                transform: translateX(-5%) translateY(50%);
            }
        }
    }
    &__list{
        width: 100%;
        position: relative;
        z-index: 1;
    }
    &__item{
        width: 100%;
        position: relative;
        margin-bottom: 35px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    &__title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        text-transform: uppercase;
        color: $baseBlack;
        margin-bottom: 16px;
        @media(max-width:1350px){
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 15px;
        }
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 10px;
        }
    }
    &__text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 150%;
        color: $baseBlack;
        .additionaly__hide-text{
            display: none;
        }
    }
    &__btn{
        margin-top: 15px;
        @media(max-width:1024px){
            margin-top: 12px;
        }
    }
}