.package-section{
    background-color: $baseBack;
    padding-top: 80px;
    padding-bottom: 15px;
    @media(max-width:1024px){
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .package__text {
        margin-bottom: 45px;
        @media(max-width:1024px){
            margin-bottom: 20px;
        }
    }
    .package__title{
        margin-bottom: 18px;
        @media(max-width:1024px){
            margin-bottom: 10px;
        }
    }
}

.package{
    &-wrapp{
        width: 100%;
        position: relative;
        z-index: 1;
        .buble__img{
            width: 170px;
            height: 170px;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translateX(-85%) translateY(-185%);
            z-index: -1;
            animation: gradient 2s infinite ease;
            @media(max-width:1024px){
                width: 90px;
                height: 90px;
                transform: translateX(-80%) translateY(-170%);
            }
        }
        .buble-two__img{
            width: 270px;
            height: 270px;
            z-index: -1;
            top: 0;
            right: 0;
            position: absolute;
            transform: translateX(50%) translateY(-45%);
            animation: gradient 2s infinite ease;
            animation-delay: 1s;
            @media(max-width:1024px){
                width: 90px;
                height: 90px;
                transform: translateX(50%) translateY(-40% );
            }
        }

    }
    &__list{
        width: calc(100% + 80px);
        margin-left: -40px;
        margin-right: -40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        position: relative;
        z-index: 1;
        @media(max-width:1500px){
            width: calc(100% + 50px);
            margin-left: -25px;
            margin-right: -25px;
        }
        @media(max-width:1400px){
            width: calc(100% + 40px);
            margin-left: -20px;
            margin-right: -20px;
        }
        @media(max-width:1300px){
            width: 100%;
            margin: 0;
        }
    }
    &__item {
        width: 33.3333333333333%;
        position: relative;
        padding: 0 40px;
        margin-bottom: 40px;
        @media(max-width:1500px){
            padding: 0 25px;
        }
        @media(max-width:1400px){
            padding: 0 20px;
        }
        @media(max-width:1300px){
            width: 100%;
            padding: 0;
        }
        @media(max-width:1024px){
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    &-card{
        width: 100%;
        height: fit-content;
        background: $baseWhite;
        border-radius: 10px;
        @media(max-width:1024px){
            border-radius: 6px;
            box-shadow: 16px 22px 60px rgba(0, 0, 0, 0.1);
        }
    }

    &-card__header{
        width: 100%;
        position: relative;
        min-height: 80px;
        padding: 15px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: $baseWhite;
        background-color: $baseBlue;
        overflow: hidden;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        @media(max-width:1350px){
            font-size: 20px;
            line-height: 25px;
            min-height: 70px;
        }
        @media(max-width:1024px){
            min-height: 60px;
            padding: 10px 15px;
            font-size: 18px;
            line-height: 22px;
             border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }
    }

    &-card__content {
        width: 100%;
        position: relative;
        background-color: $baseWhite;
        padding: 25px 30px;
        padding-bottom: 40px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        @media(max-width:1400px){
            padding: 25px;
            padding-bottom: 30px;
        }
        @media(max-width:1024px){
            padding: 20px 15px;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }

    &-card__text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */
        text-align: center;
        color: $baseBlack;
        margin-bottom: 25px;
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 15px;
        }
    }
    &-card__price{
        margin-bottom: 22px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 15px;
        }
        
    }
    &-card__btn{
        margin-left: 50%;
        transform: translateX(-50%);
    }
    &-options__list {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(max-width:1024px){
            margin-bottom: 15px;
        }
        @media(max-width:450px){
            align-items: flex-start;
        }
        li{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: $baseBlack;
            position: relative;
            text-decoration-line: underline;
            text-decoration-style: dashed;
            text-decoration-thickness: from-font;
            margin-bottom: 25px;
            text-align: center;
            padding-left: 18px;
            @media(max-width:1400px){
                font-size: 15px;
                line-height: 22px;
            }
            @media(max-width:1300px){
                font-size: 16px;
                line-height: 24px;
            }
            @media(max-width:1024px){
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 17px;
            }
            @media(max-width:450px){
                text-align: left;
            }
            &:last-child{margin-bottom: 0;}
        }
        li::before{
            background: url('../svg/done.svg') no-repeat;
            background-size: 13px 11px;
            width: 13px;
            height: 11px;
            display: flex;
            content: '';
            position: absolute;
            left: 0;
            top: 6px;
            @media(max-width:1024px){
                top: 4px;
            }
        }
    }
}