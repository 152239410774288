.footer {
  width: 100%;
  padding: 0 30px;
  background-color: $baseWhite;
  position: relative;
  @media(max-width:1024px){
    padding: 0 15px;
  }
  &__content{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-top: 1px solid $baseGray;
    @media(max-width:1024px){
      flex-wrap: wrap;
      padding: 15px 0;
      flex-direction: column;
    }
  }

  .footer__copyright{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-right: 20px;
    color: $baseBlack;
    @media(max-width:1350px){
      font-size: 14px;
      line-height: 18px;
      margin-right: 10px;
    }
    @media(max-width:1024px){
      width: 100%;
      margin: 0;
      order: 1000;
      text-align: center;
    }
  }

  &-contact__link {
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $baseBlack;
    transition: .3s;
    margin:0 20px;
    display: flex;
    @media(max-width:1350px){
      font-size: 14px;
      line-height: 18px;
      margin: 0 10px;
    }
    @media(max-width:1024px){
      margin: 0;
      margin-bottom: 15px;
    }
    .link-icon {
      max-width: 18px;
      max-height: 18px;
      min-width: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 7px;
      @media(max-width:1024px){
        margin-right: 5px;
      }
    }
    .icon{
      max-width: 18px;
      max-height: 18px;
      object-fit: contain;
      transition: .3s;
      fill: $baseBlack;
      @media(max-width:1024px){
        max-width: 18px;
        max-height: 18px;
      }
    }
    &:hover{
      color: $baseBlue;
      .icon{
        fill: $baseBlue;
      }
    }
  }

  &__btn{
    min-width: 305px;
    margin-left: 20px;
    @media(max-width:1500px){
      min-width: 260px;
    }
    @media(max-width:1350px){
      margin-left: 10px;
    }
    @media(max-width:1100px){
      min-width: 220px;
    }
    @media(max-width:1024px){
      min-width: 330px;
      margin: 0;
      margin-bottom: 15px;
    }
    @media(max-width:450px){
      min-width: 100%;
    }
  }

  .social__list{
    margin: 0 20px;
    @media(max-width:1350px){
      margin: 0 10px;
    }
    @media(max-width:1024px){
      width: 100%;
      order:4;
      justify-content: center;
      margin: 0;
      margin-bottom: 15px;
    }
  }
}
