.modal{
    &-wraper{
        width: 100%;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        height: 100vh;
        max-height: calc(var(--vh, 1vh) * 100);
        overflow: hidden;
        overflow-y: auto;
        background-color: rgba($baseWhite,0.6);
        backdrop-filter: blur(6px);
        display: flex;
        flex-direction: column;
        padding: 50px 30px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: .3s;
        @media(max-width:576px){
            padding: 40px 15px;
        }
        &.active{
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
        &.thanks-popup{
            .modal__header{
                font-size: 32px;
                @media(max-width:1350px){
                    font-size: 28px;
                }
                @media(max-width:1024px){
                    font-size: 24px;
                }
            }
        }
        &.quize-thanks-popup{
            .modal__box{
                background-color: $baseBlue;
                border: none;
                max-width: 700px;
                min-height: 320px;
                @media(max-width:1024px){
                    min-height: auto;
                    max-width: 400px;
                }
                @media(max-width:576px){
                    max-width: 100%;
                }
            }
            .modal__content{
                background-color: $baseBlue;
                border: none;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
                justify-content: center;
                z-index: 1;
                position: relative;
                overflow: hidden;
            }
            .modal-title{
                margin-bottom: 16px;
                color: $baseWhite;
            }
            .modal-form-close__btn{
                max-width: 385px;
                min-width: 385px;
                @media(max-width:1024px){
                    min-width: 100%;
                    max-width: 100%;
                }
            }
            .modal__text{
                color: $baseWhite;
                margin-bottom: 40px;
                font-weight: 600;
                line-height: 20px;
                @media(max-width:1024px){
                    line-height: 18px;
                    margin-bottom: 20px;
                }
            }
            .buble__img{
                width: 150px;
                height: 150px;
                position: absolute;
                bottom: 0%;
                left: 0;
                transform: translateX(-35%) translateY(40%);
                z-index: -1;
                animation: gradient 2s infinite ease;
                @media(max-width:1024px){
                    width: 100px;
                    height: 100px;
                    transform: translateX(-40%) translateY(40%);
                }
            }
            .buble-two__img{
                width: 240px;
                height: 240px;
                z-index: -1;
                top: 0;
                right: 0;
                position: absolute;
                transform: translateX(20%) translateY(-27% );
                animation: gradient 2s infinite ease;
                animation-delay: 1s;
                @media(max-width:1024px){
                    width: 120px;
                    height: 120px;
                    transform: translateX(20%) translateY(-30% );
                }
            }

        }
    }
    &-form-close__btn{
        min-width: 100%;
    }
    &__box{
        margin: auto;
        width: 100%;
        max-width: 496px;
        background: $baseWhite;
        border: 1px solid #D6D6D6;
        border-radius: 10px;
        position: relative;
        @media(max-width:1024px){
            max-width: 400px;
        }
        @media(max-width:576px){
            max-width: 100%;
        }
    }
    &__header{
        width: 100%;
        position: relative;
        min-height: 90px;
        background: $baseBlue;
        color: $baseWhite;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
        padding: 15px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: calc(100% +  2px);
        margin-left: -1px;
        margin-right: -1px;
        margin-top: -1px;
        @media(max-width:1350px){
            font-size: 20px;
        }
        @media(max-width:1024px){
            font-size: 18px;
            min-height: 70px;
            padding: 10px 20px;
        }
    }
    &__close{
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        transition: .3s;
        transform: translateX(45px) translateY(-35px);
        @media(max-width:1024px){
            width: 25px;
            height: 25px;
            transform: translateX(25px) translateY(-25px);
        }
        @media(max-width:576px){
            transform: translateX(0) translateY(-30px);
        }
        .icon{
            max-width: 30px;
            max-height: 30px;
            fill: $baseBlue;
            transition: .3s;
            @media(max-width:1024px){
                max-width: 25px;
                max-height: 25px;
            }
        }
        &:hover{
            .icon{
                fill: $basePurple;
            }
        }
    }

    &__content {
        width: 100%;
        position: relative;
        background-color: $baseWhite;
        padding: 25px 55px;
        padding-bottom: 38px;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        @media(max-width:1024px){
            padding: 20px;
        }
    }
    &__text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 100%;
        text-align: center;
        color: $baseBlack;
        margin-bottom: 30px;
        @media(max-width:1024px){
            font-size: 14px;
            margin-bottom: 20px;
        }
    }
    &-form{
        width: 100%;
        position: relative;
    }
    &-form__btn{
        min-width: 100%;
    }

}