.banner-section{
    padding-top: 35px;
    padding-bottom: 50px;
    @media(max-width:1024px){
        padding-top: 25px;
        padding-bottom: 30px;
    }
    .buble__img{
        width: 150px;
        height: 150px;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateX(-45%) translateY(40%);
        z-index: -1;
        animation: gradient 2s infinite ease;
        @media(max-width:1024px){
            width: 90px;
            height: 90px;
            transform: translateX(-40%) translateY(40%);
        }
    }
    .buble-two__img{
        width: 195px;
        height: 195px;
        z-index: -1;
        top: 0;
        right: 0;
        position: absolute;
        transform: translateX(50%) translateY(-45% );
        animation: gradient 2s infinite ease;
        animation-delay: 1s;
        @media(max-width:1024px){
            width: 90px;
            height: 90px;
            transform: translateX(50%) translateY(-40% );
        }
    }
}

.banner-box {
    width: 100%;
    position: relative;
    background: $baseBlue;
    border-radius: 10px;
    min-height: 340px;
    padding: 50px 110px;
    display: flex;
    align-items: center;
    z-index: 1 ;
    pointer-events: all;
    .banner__title{
        color: $baseWhite;
        margin-bottom: 15px;
        @media(max-width:1024px){
            margin-bottom: 10px;
        }
    }
    .banner__text {
        color: $baseWhite;
        margin-bottom: 37px;
        font-weight: normal;
        @media(max-width:1200px){
            margin-bottom: 30px;
        }
        @media(max-width:1024px){
            margin-bottom: 20px;
        }
    }
    // audit-add banner 
    &.audit-add-banner {
        margin-top: -170px;
        overflow: hidden;
        @media(max-width:1300px){
            margin-top: -150px;
        }
        @media(max-width:1024px){
            margin-top: 0;
        }
        .banner-box__photo{
            height: 340px;
            object-fit: contain;
            max-width: 100%;
            bottom: 0;
            right: 100px;
            img{
                object-fit: contain;
                height: 100%;
            }
            @media(max-width:1450px){
                right: 60px;
            }
            @media(max-width:1400px){
                right: 20px;
            }
            @media(max-width:1300px){
                height: 300px;
                width: auto;
                max-width: none;
            }
            @media(max-width:1024px){
                height: 250px;
            }
        }
    }

    // quize-add-banner
    &.quize-add-banner{
        margin-bottom: -250px;
        overflow: visible;
        min-height: 500px;
        padding: 50px 70px;
        @media(max-width:1450px){
            padding: 40px 70px;
        }
        @media(max-width:1300px){
            margin-bottom: -200px;
            min-height: 400px;
            padding: 40px 50px;
        }
        @media(max-width:1200px){
            padding: 30px;
        }
        @media(max-width:1024px){
            margin-bottom: 0;
            border-radius: 6px;
            padding: 20px;
            min-height: 300px;
        }
        @media(max-width:700px){
            min-height: 200px;
        }
        .banner-box__photo{
            height: calc(100% + 60px);
            object-fit: contain;
            max-width: 100%;
            width: auto;
            bottom: -25px;
            right: 130px;
            img{
                object-fit: contain;
                height: 100%;
            }
            @media(max-width:1450px){
                right: 60px;
            }
            @media(max-width:1300px){
                height: calc(100% + 40px);
                bottom: -17px;
                right: 20px;
            }
            @media(max-width:1024px){
                height: calc(100% + 20px);
                bottom: -7px;
                right: 7px; 
            }
        }
        .banner-box__info{
            max-width: 530px;
            position: relative;
            z-index: 1;
            @media(max-width:1200px){
                max-width: 450px;
            }
            @media(max-width:1024px){
                max-width: 400px;
            }
            @media(max-width:700px){
                max-width: 100%;
            }
        }
        
    }

    @media(max-width:1450px){
        padding: 40px 70px;
    }
    @media(max-width:1300px){
        padding: 40px 50px;
        min-height: 300px;
    }
    @media(max-width:1200px){
        padding: 30px;
        min-height: 250px;
    }
    @media(max-width:1024px){
        padding: 20px;
        border-radius: 6px;
    }
    @media(max-width:700px){
        min-height: 200px;
    }
    &-wrapp{
        position: relative;
        z-index: 1;
    }
    
    &__info{
        max-width: 700px;
        position: relative;
        z-index: 1;
        @media(max-width:1200px){
            max-width: 550px;
        }
        @media(max-width:1024px){
            max-width: 400px;
        }
        @media(max-width:700px){
            max-width: 100%;
        }
    }

    &__photo{
        position: absolute;
        max-width: 470px;
        z-index: 0;
        right: 70px;
        bottom: 30px;
        @media(max-width:1450px){
            max-width: 420px;
            right: 50px;
            bottom: 40px;
        }
        @media(max-width:1300px){
            max-width: 370px;
            right: 40px;
        }
        @media(max-width:1200px){
            max-width: 350px;
            right: 30px;
            bottom: 30px;

        }
        @media(max-width:1024px){
            max-width: 320px;
            right: 20px;
            bottom: 20px;
        }
        @media(max-width:768px){
            max-width: 280px;
            bottom: 30px;
        }
        @media(max-width:700px){
            display: none;
        }
        img{
            width: 100%;
            object-fit: contain;
            pointer-events: none;
        }
    }
}