$baseWhite: #fff;
$baseBlack: #000;
$baseBlue: #75AFF9;
$basePurple: #C187E9; 
$baseGray: #C6C6C6;
$secondaryGray: #B3B3B3;
$secondaryBlue: #98C5FF;
$baseBack: #FBFBFB;
$thirdGray: #A4A4A4;
$baseRed: #FC6E6E;
$secondaryBlack: #656565;