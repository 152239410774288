.modal-quize{
    &-box{
        width: 100%;
        max-width: 1440px;
        margin: auto;
        position: relative;
        z-index: 1;
        .modal__close{
            transform: translateX(0) translateY(-40px);
            @media(max-width:1024px){
                width: 25px;
                height: 25px;
                transform: translateX(0) translateY(-30px);
            }
            @media(max-width:576px){
                transform: translateX(0) translateY(-30px);
            } 
        }
    }
    
}
.quize-modal-wrapper{
    @media(max-width:1024px){
        padding-left: 15px;
        padding-right: 15px;
    }
}

// stepper
.quize-stepper{
    width: 100%;
    position: relative;
    background-color: $baseBlue;
    border-radius: 10px;
    padding: 40px 70px;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    height: 100%;
    @media(max-width:1450px){
        min-height: 560px;
    }
    @media(max-width:1300px){
        padding: 40px 50px;
    }
    @media(max-width:1200px){
        padding: 30px;
    }
    @media(max-width:1024px){
        border-radius: 6px;
        padding: 20px;
        min-height: 500px;
    }
    // @media(max-width:700px){
    //     min-height: auto;
    // }
    // progress
    &-progress{
        width: 100%;
        position: relative;
        z-index: 2;
    }
    &-progress__status{
        transition: .3s;
        position: relative;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        color: $baseWhite;
        padding-bottom: 17px;
        z-index: 1;
        @media(max-width:1450px){
            font-size: 20px;
            line-height: 26px;
            padding-bottom: 15px;
        }
        @media(max-width:1024px){
            font-size: 18px;
            line-height: 22px;
            padding-bottom: 10px;
        }
        .value{
            font-size: 48px;
            // line-height: 60px;
            @media(max-width:1450px){
                font-size: 40px;
            }
            @media(max-width:1024px){
                font-size: 32px;
            }
        }
        &::after{
            width: 100%;
            position: absolute;
            height: 10px;
            background-color: $basePurple;
            content: '';
            right: 0;
            bottom: 0;
            @media(max-width:1450px){
                height: 8px;
            }
            @media(max-width:1024px){
                height: 5px;
            }
        }
    }
    &-progress__line{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        height: 10px;
        background-color: $baseWhite;
        transition: .3s;
        @media(max-width:1450px){
            height: 8px;
        }
        @media(max-width:1024px){
            height: 5px;
        }
    }
    &-progress__fill{
        width: 0%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border: 4px solid #C187E9;
        @media(max-width:1450px){
            height: 8px;
            border: 3px solid #C187E9;
        }
        @media(max-width:1024px){
            height: 5px;
            border: 2px solid #C187E9;
        }
    }

    // stepper list
    &__list{
        width: 100%;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &__item{
        width: 100%;
        position: relative;
        opacity: 0;
        transition: .2s;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 0;
        z-index: 1;
        visibility: hidden;
        @media(max-width:1450px){
            padding: 20px 0;
            padding-bottom: 10px;
        }
        @media(max-width:700px){
            padding-bottom: 5px;
            padding-top: 15px;
        }
        &.active{
            opacity: 1;
            visibility: visible;
        }

        &.quize-stepper__one{
            .quize-stepper__img{
                max-width: 710px;
                bottom: -60px;
                @media(max-width:1450px){
                    max-width: 560px;
                }
                @media(max-width:1300px){
                    max-width: 500px;
                    bottom: -50px;
                }
                @media(max-width:1024px){
                    max-width: 450px;
                    bottom: -30px;
                    right: -20px;
                }
                @media(max-width:800px){
                    max-width: 350px;
                    bottom: -30px;
                    right: -20px;
                }
            }
        }
        &.quize-stepper__two{
            .quize-stepper__img{
                max-width: 965px;
                bottom: auto;
                top: -96px;
                right: -120px;
                @media(max-width:1450px){
                    max-width: 780px;
                    top:-88px;
                    right: -110px;
                }
                @media(max-width:1300px){
                    max-width: 760px;
                    right: -80px;
                }
                @media(max-width:1200px){
                    top: -78px;
                }
                @media(max-width:1024px){
                    max-width: 550px;
                    right: -40px;
                    top: auto;
                    bottom: -40px;
                }
                @media(max-width:800px){
                    max-width: 450px;
                    right: -40px;
                    bottom: -30px;
                }
            }
        }
        &.quize-stepper__three{
            .quize-stepper__img{
                max-width: 745px;
                bottom: -70px;
                right: -30px;
                @media(max-width:1450px){
                    max-width: 560px;
                    bottom: -60px;
                }
                @media(max-width:1200px){
                    bottom: -50px;
                }
                @media(max-width:1024px){
                    max-width: 450px;
                    bottom: -35px;
                    right: -20px;
                }
                @media(max-width:800px){
                    max-width: 390px;

                }
            }
        }
        &.quize-stepper__four{
            .quize-stepper__img{
                max-width: 665px;
                bottom: -80px;
                right: 45px;
                @media(max-width:1450px){
                    max-width: 500px;
                    bottom: -60px;
                }
                @media(max-width:1200px){
                    bottom: -50px;
                    right: 20px;
                }
                @media(max-width:1024px){
                    max-width: 450px;
                    bottom: -35px;
                    right: -20px;
                }
                @media(max-width:800px){
                    max-width: 370px;

                }
            }
        }
        &.quize-stepper__five{
            .quize-stepper__img{
                max-width: 935px;
                bottom: -90px;
                right: -130px;
                @media(max-width:1450px){
                    max-width: 780px;
                    right: -110px;
                }
                @media(max-width:1300px){
                    max-width: 700px;
                    right: -100px;
                    bottom: -75px;
                }
                @media(max-width:1200px){
                    right: -85px;
                }
                @media(max-width:1024px){
                    max-width: 550px;
                    right: -40px;
                    // top: auto;
                    bottom: -45px;
                }
                @media(max-width:900px){
                    max-width: 500px;
                    right: -50px;
                }
                @media(max-width:800px){
                    max-width: 450px;
                }
            }
        }
        &.quize-stepper__six{
            .quize-stepper__navigation-title{
                margin-bottom: 50px;
                @media(max-width:1450px){
                    margin-bottom: 35px;
                }
                @media(max-width:1024px){
                    margin-bottom: 20px;
                }
            }
            .quize-stepper__img{
                max-width: 700px;
                bottom: -84px;
                right: 0;
                @media(max-width:1450px){
                    max-width: 530px;
                    bottom: -80px;
                }
                @media(max-width:1200px){
                    bottom: -70px;
                    right: -10px;
                }
                @media(max-width:1024px){
                    max-width: 450px;
                    bottom: -50px;
                    right: -10px;
                }
                @media(max-width:800px){
                    max-width: 370px;
                    bottom: -40px;

                }
            }
        }
        &.quize-stepper__seven{
            .quize-stepper__img{
                max-width: 790px;
                bottom: -70px;
                right: 5px;
                @media(max-width:1450px){
                    max-width: 650px;
                    bottom: -50px;
                }
                @media(max-width:1200px){
                    bottom: -40px;
                    max-width: 600px;
                }
                @media(max-width:1024px){
                    max-width: 500px;
                    bottom: -35px;
                    right: -20px;
                }
                @media(max-width:900px){
                    max-width: 430px;
                    bottom: -30px;
                }
                @media(max-width:800px){
                    max-width: 350px;

                }
            }
            .quize-stepper__title{
                margin-bottom: 16px;
            }
            .quize-stepper__text{
                max-width: 380px;
                @media(max-width:700px){
                    max-width: 100%;
                }
            }
            padding: 0;
            
        }
    }
    &__img{
        width: 100%;
        position: absolute;
        z-index: -1;
        right: 0;
        bottom: 0;
        object-fit: contain;
        pointer-events: none;
        max-width: 700px;
        @media(max-width:1450px){
            max-width: 550px;
        }
        @media(max-width:1024px){
            max-width: 420px;
        }
        @media(max-width:800px){
            max-width: 350px;
        }

        @media(max-width:700px){
            display: none;
        }
    }
    &__item-info{
        width: 100%;
        max-width: 550px;
        transition: .2s;
        opacity: 0;
        visibility: hidden;
        position: relative;
        z-index: 1;
        @media(max-width:1300px){
            max-width: 420px;
        }
        @media(max-width:1024px){
            max-width: 350px;
        }
        @media(max-width:700px){
            max-width: 100%;
        }
        &.active{
            opacity: 1;
            visibility: visible;
        }
    }
    &__radiofield{
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        .form__radio{
            margin-bottom: 40px;
            @media(max-width:1450px){
                margin-bottom: 30px;
            }
            @media(max-width:1024px){
                margin-bottom: 20px;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    &__title {
        color: $baseWhite;
        margin-bottom: 50px;
        overflow-wrap: break-word;
        @media(max-width:1450px){
            margin-bottom: 35px;
        }
        @media(max-width:1024px){
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 20px;
        }
    }
    &__text{
        margin-bottom: 40px;
        color: $baseWhite;
        font-weight: 600;
        @media(max-width:1450px){
            margin-bottom: 20px;
        }
    }

    // hide navigation
    &__navigation{
        width: 100%;
        position: relative;
        z-index: 1;
        max-width: 630px;
        position: relative;
        transition: .3s;
        opacity: 0;
        visibility: hidden;
        @media(max-width:1450px){
            max-width: 580px;
        }
        @media(max-width:1300px){
            max-width: 470px;
        }
        @media(max-width:1024px){
            max-width: 400px;
        }
        @media(max-width:700px){
            max-width: 100%;
        }
        &.active{
            opacity: 1;
            visibility: visible;
        }
    }
    &__buttons{
        width: 100%;
        display: flex;
        flex-direction: column;
        .global-btn{
            margin-bottom: 20px;
            max-width: 325px;
            @media(max-width:1450px){
                margin-bottom: 15px;
            }
            @media(max-width:700px){
                max-width: 100%;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    &__prev{
        background-color: transparent;
        border-color: $baseWhite;
        color: $baseWhite;
        &::after{
            background-color: $baseWhite;
        }
        &:hover{
            color: $baseBlue;
            .icon{
                fill: $baseBlue;
            }
        }
        .icon{
            margin-right: 14px;
            fill: $baseWhite;
            margin-left: 0;
            transform: scale(-1,1);
        }
    }
    &__navigation-title{
        color: $baseWhite;
        margin-bottom: 20px;
        overflow-wrap: break-word;
        @media(max-width:1024px){
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 20px;
        }
    }
    &__navigation-text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $baseWhite;
        margin-bottom: 50px;
        @media(max-width:1450px){
            margin-bottom: 30px;
        }
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 20px;
        }
    }
    // hide navigation end

    // form

    &__formbox{
        max-width: 385px;
        @media(max-width:700px){
            max-width: 100%;
        }
        .form__field-name{
            color: $baseWhite;
        }
        .form__input{
            border-color: $baseWhite;
            color: $baseWhite;
            font-weight: 500;
            &::placeholder{
                color: #DCEBFF;
            }
        }
        .form__checkbox-text{
            color: $baseWhite;
        }
        .form__checkbox-retangle{
            border-color: $baseWhite;
        }
        .form__checkbox{
            // margin-bottom: 40px;
            &:hover{
                .form__checkbox-retangle{
                    border-color: $baseWhite;
                    background-color: $basePurple;
                }
                .form__checkbox-text{
                    color: $baseWhite;
                    opacity: 0.8;
                }
            }
            .icon{
                fill: $baseWhite;
            }
            input:checked+ .form__checkbox-retangle{
                border-color: $basePurple;
                background-color: $basePurple;
                .icon{
                    opacity: 1;
                }
            }
        }
    }
    // form end
}

// stepper end