.roadmap-section {
    padding-top: 50px;
    padding-bottom: 70px;
    @media(max-width:1024px){
        padding-top: 25px;
        padding-bottom: 40px;
    }
    .roadmap__title{
        margin-bottom: 18px;
        @media(max-width:1024px){
            margin-bottom: 10px;
        }
    }
    .roadmap__text {
        margin-bottom: 40px;
        @media(max-width:1024px){
            margin-bottom: 20px;
        }
    }
}